<template>
  <v-container id="category" fluid tag="section">
    <v-card>
      <v-alert color="red lighten-2" dark v-if="showError">
        {{ showErrorData }}
      </v-alert>
      <v-form ref="form">
        <v-container class="py-2">
          <v-row>
            <v-col cols="6" md="6">
              <v-text-field
                label="Name"
                class="purple-input"
                v-model="name"
                :rules="inputRule"
                :disabled="onlyView"
              />
            </v-col>
            <!-- <v-col cols="6" md="6">
              <v-textarea
                class="purple-input"
                label="Description"
                v-model="description"
                :disabled="onlyView"
              />
            </v-col> -->

            <v-col cols="6" md="6">
              <v-text-field
                type="number"
                class="purple-input"
                label="Margin (in Percentage)"
                v-model="margin"
                :rules="inputRule"
                :disabled="onlyView"
              />
            </v-col>
            <v-col cols="6" md="6">
              <v-text-field
                type="number"
                class="purple-input"
                label="Position"
                v-model="position"
                :rules="inputRule"
                :disabled="onlyView"
              />
            </v-col>

            <v-col cols="6" md="6">
              <v-file-input
                show-size
                label="Images"
                truncate-length="15"
                v-model="files"
                :disabled="onlyView"
                accept="image/*"
                :persistent-hint="hint"
                hint="Maximum accepted image size : 25kb"
                @change="checkFileSize()"
              ></v-file-input>
              <ImageErrorDialog
                :error-dialog="errorDialog"
                :error-message="errorMessage"
                @close="errorDialog = false"
              ></ImageErrorDialog>
              <div v-if="images != null && images !== ''">
                <v-col style="padding-left: 30px">
                  <v-avatar>
                    <v-img
                      aspect-ratio="1"
                      :src="imageUrl + images"
                      :lazy-src="imageUrl + images"
                      @click="openNewTab(imageUrl + images)"
                      style="cursor: pointer"
                    />
                  </v-avatar>
                  <v-btn
                    icon
                    x-small
                    rounded
                    color="error"
                    style="background-color: #fff !important"
                    @click="deleteImage(images)"
                  >
                    <v-icon> mdi-delete </v-icon>
                  </v-btn>
                </v-col>
                <!-- <v-avatar v-if="images != null">
                  <v-img
                    aspect-ratio="1"
                    :src="imageUrl + images"
                    :lazy-src="imageUrl + images"
                    @click="deleteImage(images)"
                    style="cursor: pointer"
                  />
                </v-avatar> -->
                <v-dialog v-model="imageDelete" max-width="500px">
                  <v-card>
                    <v-card-title
                      >Are you sure you want to delete image?</v-card-title
                    >
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="primary darken-1"
                        depressed
                        @click="closeDelete"
                        >Cancel</v-btn
                      >
                      <v-btn
                        color="primary darken-1"
                        depressed
                        @click="deleteItemConfirm"
                        >Delete</v-btn
                      >
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </div>
            </v-col>

            <v-col cols="6" md="6">
              <v-checkbox
                v-model="isHidden"
                label="Tick to hide this category."
                label-color="red"
                color="#cf9602"
                hide-details
                :disabled="onlyView"
                class="hidden-policy-checkbox"
              ></v-checkbox>
            </v-col>
            <v-col cols="6" md="6">
              <v-checkbox
                v-model="isTobacco"
                label="Tick to make this tobacco category."
                label-color="red"
                color="#cf9602"
                hide-details
                :disabled="onlyView"
                class="hidden-policy-checkbox"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="text-right">
              <v-btn
                type="button"
                color="primary"
                class="mr-0"
                @click="submit"
                :loading="loading"
              >
                {{
                  onlyView == true
                    ? "Back"
                    : editCategory == true
                    ? "Update"
                    : "Submit"
                }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import ImageErrorDialog from "@/views/dashboard/components/modals/ImageErrorDialog.vue";

export default {
  props: ["id", "action"],
  components: {
    ImageErrorDialog
  },
  data() {
    return {
      isHidden: false,
      isTobacco: false,
      name: "",
      description: "",
      margin: "0",
      position: 0,
      files: [],
      images: null,
      editCategory: null,
      imageDelete: false,
      categoryImageDelete: null,
      showError: false,
      showErrorData: null,
      // sendFiles: [],
      inputRule: [v => !!v || "Field is required"],
      fileuploadSize: [
        v => !v || v.size < 25000 || "Image Size should be less than 25 KB!"
      ],
      onlyView: false,
      loading: false,
      imageUrl: process.env.VUE_APP_Image_Link + "Categories/",
      hint: true,
      errorDialog: false,
      errorMessage: ""
    };
  },
  methods: {
    getCategoryData(id) {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + "category/edit/" + id)
        .then(response => {
          if (response.status == 200) {
            this.name = response.data.category.name;
            this.description = response.data.category.description;
            this.margin = response.data.category.margin;
            this.position = response.data.category.position;
            this.images = response.data.category.images;
            this.isHidden = response.data.category.hidden;
            this.isTobacco = response.data.category.is_tobacco;
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    openNewTab(url) {
      window.open(url, "_blank");
    },
    deleteImage(deleteImage) {
      if (this.onlyView == false) {
        this.imageDelete = true;
        this.categoryImageDelete = deleteImage;
      }
    },
    deleteItemConfirm() {
      let data = { id: this.id, name: this.categoryImageDelete };
      axios
        .post(process.env.VUE_APP_API_BASE_URL + "category/delete/image", data)
        .then(response => {
          if (response.status == 200) {
            this.closeDelete();
            this.getCategoryData(this.id);
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    closeDelete() {
      this.imageDelete = false;
    },
    submit() {
      this.showError = false;
      if (this.$refs.form.validate()) {
        this.loading = true;
        let formData = new FormData();
        formData.append("id", this.id);
        formData.append("name", this.name);
        formData.append("description", this.description);
        formData.append("margin", this.margin);
        formData.append("position", this.position);
        formData.append("hidden", this.isHidden);
        formData.append("is_tobacco", this.isTobacco);
        if (this.files.length != 0) {
          formData.append("images", this.files);
        }
        if (this.onlyView) {
          this.$router.push({ name: "Categories" });
        } else if (this.editCategory == true) {
          axios
            .post(
              process.env.VUE_APP_API_BASE_URL + "category/update",
              formData
            )
            .then(response => {
              if (response.status == 200) {
                this.loading = false;
                this.$router.push({
                  name: "Categories",
                  params: { edit: true }
                });
              }
            })
            .catch(error => {
              this.loading = false;
              if (error.response.data.name != null) {
                this.showError = true;
                this.showErrorData = error.response.data.name[0];
              }
              if (error.response.data.images != null) {
                this.showError = true;
                this.showErrorData = error.response.data.images[0];
              }
            });
        } else {
          axios
            .post(
              process.env.VUE_APP_API_BASE_URL + "category/create",
              formData
            )
            .then(response => {
              if (response.status == 200) {
                this.loading = false;
                this.$router.push({
                  name: "Categories",
                  params: { add: true }
                });
              }
            })
            .catch(error => {
              this.loading = false;
              if (error.response.data.name != null) {
                this.showError = true;
                this.showErrorData = error.response.data.name[0];
                if ((this.showError = true)) {
                  window.scrollTo(0, 0);
                }
              }
              if (error.response.data.images != null) {
                this.showError = true;
                if ((this.showError = true)) {
                  window.scrollTo(0, 0);
                }
                this.showErrorData = error.response.data.images[0];
              }
            });
        }
      }
    },
    checkFileSize() {
      const maxSizeInBytes = 25 * 1024; // 25kb
      const files = this.files;

      if (files) {
        if (files.size > maxSizeInBytes) {
          // this.errorMessage = `File '${files.name}' exceeds the maximum size limit (50kb).`;
          this.errorMessage = `Please choose an image with size less than 25 KB.`;
          this.errorDialog = true;
          this.files = []; // Clear the selected files
          return;
        }
      }
    }
  },
  mounted() {
    if (this.id != undefined) {
      this.getCategoryData(this.id);
      this.editCategory = true;
    } else {
      this.editCategory = false;
    }
    if (this.action == "view") {
      this.onlyView = true;
    }
  }
};
</script>

<style>
.hidden-policy-checkbox .v-label {
  color: red; /* Change the label color to red */
}
</style>
